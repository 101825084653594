import Vue from "vue";
import Router from "vue-router";
import store from "@/stores/store";
import i18n from "@/plugins/i18n.js";
import restrictHelper from "./_helpers/RestrictHelper.js";

// Import components
// const Home = () => import("@/views/Landing.vue");
const Home = () => import("@/views/Home.vue");

const Company = () => import("@/views/company/Company.vue");
const CompanyUsers = () => import("@/components/company/Users.vue");
const CompanyEdit = () => import("@/components/company/Edit.vue");
const CompanyCreate = () => import("@/components/company/Create.vue");
const CreateUser = () => import("@/components/company/CreateUser.vue");
const CompanyApps = () => import("@/components/company/Applications.vue");

const UserCompanyRegister = () => import("@/components/user/Register");
const EditUser = () => import("@/components/user/Detail");

const ForgotPassword = () => import("@/views/ForgotPassword.vue");
const ForgotPasswordComponent = () =>
  import("@/components/password/ForgotPasswordComponent.vue");
const NewPassword = () => import("@/components/password/NewPassword.vue");

const Login = () => import("@/views/Login.vue");
const Register = () => import("@/views/Register.vue");
const Logout = () => import("@/views/Logout.vue");

const ScriptView = () => import("@/views/Script.vue");
const ScriptList = () => import("@/components/script/ScriptList.vue");
const ScriptCreate = () => import("@/components/script/ScriptCreate.vue");
const ScriptEdit = () => import("@/components/script/ScriptEdit.vue");

const News = () => import("@/views/News.vue");
const SettingView = () => import("@/views/Setting.vue");
const Settings = () => import("@/components/setting/SettingEdit.vue");

const RoleContainer = () => import("@/views/RolesContainer.vue");
const RoleList = () => import("@/components/roles/RoleList.vue");
const RoleCreate = () => import("@/components/roles/RoleCreate.vue");
const RoleEdit = () => import("@/components/roles/RoleEdit.vue");

const SiloView = () => import("@/views/SiloView.vue");
const SiloList = () => import("@/components/silos/SiloList.vue");
const SiloCreate = () => import("@/components/silos/SiloCreate.vue");
const SiloUpdate = () => import("@/components/silos/SiloUpdate.vue");

const SiloPropertyView = () => import("@/views/SiloPropertyView.vue");
const SiloPropertyList = () =>
  import("@/components/silos/SiloPropertyList.vue");
const SiloPropertyCreate = () =>
  import("@/components/silos/SiloPropertyCreate.vue");
const SiloPropertyUpdate = () =>
  import("@/components/silos/SiloPropertyUpdate.vue");

const AlarmView = () => import("@/views/AlarmView.vue");
const AlarmList = () => import("@/components/alarm/AlarmList.vue");
const AlarmCreate = () => import("@/components/alarm/AlarmCreate.vue");
const AlarmEdit = () => import("@/components/alarm/AlarmEdit.vue");
const AlarmActiveList = () => import("@/components/alarm/AlarmActiveList.vue");

const ContentView = () => import("@/views/ContentView.vue");
const ContentList = () => import("@/components/contents/ContentList.vue");
const ContentCreate = () => import("@/components/contents/ContentCreate.vue");
const ContentUpdate = () => import("@/components/contents/ContentUpdate.vue");

const HomeDashboard = () => import("@/components/dashboard/HomeDashboard.vue");

import { Roles } from "@/_helpers/Role";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { permit: "ALL" },
    },

    {
      path: "/dashboard",
      name: "home.dashboard",
      component: HomeDashboard,
      meta: { permit: "Dashboards.View" },
    },

    {
      path: "/alarms",
      name: "alarm.default.view",
      component: AlarmView,
      meta: { authorize: "Alarms.View" },
      children: [
        {
          path: "",
          component: AlarmList,
          meta: { permit: "Alarms.View" },
        },

        {
          path: "actives",
          component: AlarmActiveList,
          meta: { permit: "Alarms.View" },
        },

        {
          path: "create",
          component: AlarmCreate,
          meta: { permit: "Alarms.Create" },
        },

        {
          path: ":id",
          component: AlarmEdit,
          meta: { permit: "Alarms.Update" },
        },
      ],
    },

    {
      path: "/silos",
      component: SiloView,
      meta: { permit: "Silos.View" },
      children: [
        {
          path: "",
          component: SiloList,
          meta: { permit: "Silos.View" },
        },

        {
          path: "create",
          component: SiloCreate,
          meta: { permit: "Silos.Create" },
        },

        {
          path: ":id",
          component: SiloUpdate,
          meta: { permit: "Silos.Update" },
        },
      ],
    },

    {
      path: "/contents",
      component: ContentView,
      meta: { permit: "SiloContents.View" },
      children: [
        {
          path: "",
          component: ContentList,
          meta: { permit: "SiloContents.View" },
        },

        {
          path: "create",
          component: ContentCreate,
          meta: { permit: "SiloContents.Create" },
        },

        {
          path: ":id",
          component: ContentUpdate,
          meta: { permit: "SiloContents.Update" },
        },
      ],
    },

    {
      path: "/properties",
      component: SiloPropertyView,
      meta: { permit: "SiloProperties.View" },
      children: [
        {
          path: "",
          component: SiloPropertyList,
          meta: { permit: "SiloProperties.View" },
        },

        {
          path: "create",
          component: SiloPropertyCreate,
          meta: { permit: "SiloProperties.Create" },
        },

        {
          path: ":id",
          component: SiloPropertyUpdate,
          meta: { permit: "SiloProperties.Update" },
        },
      ],
    },

    {
      path: "/settings",
      component: SettingView,
      meta: { authorize: Roles.CompanyOrUser },
      children: [
        {
          path: "",
          name: "setting/edit",
          component: Settings,
        },
      ],
    },

    {
      path: "/script",
      component: ScriptView,
      children: [
        {
          path: "",
          component: ScriptList,
          meta: { permit: "Script.View" },
        },

        {
          path: "create",
          component: ScriptCreate,
          meta: { permit: "Script.Create" },
        },

        {
          path: ":id",
          component: ScriptEdit,
          meta: { permit: "Script.Update" },
        },
      ],
    },

    {
      path: "/company",
      component: Company,
      children: [
        {
          path: "",
          component: CompanyUsers,
          meta: { permit: "Company.View" },
        },
        {
          path: "edit",
          component: CompanyEdit,
          meta: { permit: "Company.Update" },
        },
        {
          path: "create",
          component: CompanyCreate,
          meta: { permit: "Company.Create" },
        },
        {
          path: "applications",
          component: CompanyApps,
          meta: { permit: "Application.View" },
        },
        {
          path: "usercreate",
          component: CreateUser,
          meta: { permit: "User.Create" },
        },
        {
          path: "user/:id",
          component: EditUser,
          meta: { permit: "User.Update" },
        },
      ],
    },

    {
      path: "/forgot",
      component: ForgotPassword,
      children: [
        {
          path: "",
          component: ForgotPasswordComponent,
        },
        {
          path: ":id",
          component: NewPassword,
        },
      ],
    },

    {
      path: "/roles",
      component: RoleContainer,
      children: [
        {
          path: "",
          component: RoleList,
          meta: { permit: "Roles.View" },
        },
        {
          path: "create",
          component: RoleCreate,
          meta: { permit: "Roles.Create" },
        },

        {
          path: "edit/:id",
          component: RoleEdit,
          meta: { permit: "Roles.Update" },
        },
      ],
    },

    {
      path: "/register",
      component: Register,
      meta: { permit: "Company.Create" },
      children: [
        {
          path: "",
          component: UserCompanyRegister,
          meta: { permit: "Company.Create" },
        },
      ],
    },

    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/logout",
      name: "Logout",
      component: Logout,
    },

    {
      path: "/news",
      name: "News",
      component: News,
    },
  ],

  duplicateNavigationPolicy: "reload",
});

router.beforeEach((to, from, next) => {
  // Router guard for forcing user to login
  if (store.state.users.status.loggedIn == false && to.path !== "/login") {
    router.push("/login");
    return;
  }

  if (
    store.state.languages !== undefined &&
    store.state.languages.language &&
    store.state.languages.language !== i18n.locale
  ) {
    i18n.locale = store.state.languages.language;
    next();
  } else if (
    store.state.languages === undefined ||
    !store.state.languages.language
  ) {
    store
      .dispatch("languages/setLanguage", navigator.languages, { root: true })
      .then(() => {
        i18n.locale = store.state.languages.language;
        next();
      });
  }

  if (to.meta.authorize) {
    if (restrictHelper.methods.permitted(to.meta.permit)) {
      next();
    } else {
      if (store.state.users.status.loggedIn == false) return { name: "Login" };
      return;
    }
  }

  next();
});

export default router;
