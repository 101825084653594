import Repository from "../Repository";
import {getAuthHeader, getConfig} from "../../_helpers/apiHelper";

const resource = "/data";

export default {
  getSiloData(user, siloId, from, to) {
      const config = {
        headers: getAuthHeader(user),
      };

      return Repository.get(
        `${resource}/${siloId}/${encodeURIComponent(from)}/${encodeURIComponent(
          to
        )}`,
        config
      );
  },

  getSiloDataPrediction(siloId, from) {
    const config = getConfig();
      return Repository.get(
        `${resource}/prediction/${siloId}/${encodeURIComponent(from)}`,
        config
      );
    },
};
