import ContactRepository from "../../api/repositories/contactRepository.js";

export const contacts = {
  namespaced: true,
  state: {
    contacts: [],
  },

  actions: {
    async getContacts({ commit, rootState }) {
      await ContactRepository.getContacts(
        rootState.users.currentUser,
        rootState.users.userCompany.companyId
      )
        .then((d) => {
          commit("SET_CONTACTS", d.data);
        })
        .catch((res) => {
          // eslint-diable-next-line
          console.error(res);
        });
    },
  },

  mutations: {
    SET_CONTACTS(state, contacts) {
      state.contacts = contacts;
    },
  },
};
