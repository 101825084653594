import { RepositoryFactory } from "../../api/RepositoryFactory";

const DeviceRepo = RepositoryFactory.get("devices");

export const devices = {
  namespaced: true,
  state: {
    devices: [],
  },

  actions: {
    async getAvaliableDevices({ dispatch, commit, rootState }) {
      if (!rootState.users.status.loggedIn) return;

      var user = rootState.users.currentUser;
      var companyId = rootState.users.userCompany.companyId;
      await DeviceRepo.getAvaliableDevices(user, companyId)
        .then((devices) => {
          commit("GET_DEVICES", devices.data);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }
        });
    },

    addDevice({ commit }, device) {
      commit("ADD_DEVICE", device);
    },
  },

  mutations: {
    GET_DEVICES(state, devices) {
      state.devices = devices;
    },

    ADD_DEVICE(state, device) {
      state.devices.push(device);
    },
  },
};
