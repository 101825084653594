import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/silocontent";

export default {
  getSiloContents(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${companyId}`, config);
  },

  createSiloContent(user, companyId, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/${companyId}`, payload, config);
  },

  updateSiloContent(user, siloContentId, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(`${resource}/${siloContentId}`, payload, config);
  },

  deleteSiloContent(user, siloContentId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/${siloContentId}`, config);
  },
};
