import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/silo";

export default {
  getSilos(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${companyId}`, config);
  },

  getSiloProperties(user, companyId) {
    var config = { headers: getAuthHeader(user) };

    return Repository.get(`${resource}/properties/${companyId}`, config);
  },

  calculatePropertyVolume(user, payload) {
    var config = { headers: getAuthHeader(user) };

    return Repository.post(`${resource}/property/calc`, payload, config);
  },

  createSilo(user, companyId, payload) {
    var config = { headers: getAuthHeader(user) };

    return Repository.post(`${resource}/${companyId}`, payload, config);
  },

  updateSilo(user, siloId, payload) {
    var config = { headers: getAuthHeader(user) };

    return Repository.put(`${resource}/${siloId}`, payload, config);
  },

  deleteSilo(user, siloId) {
    var config = { headers: getAuthHeader(user) };

    return Repository.delete(`${resource}/${siloId}`, config);
  },

  createProperty(user, companyId, payload) {
    var config = { headers: getAuthHeader(user) };

    return Repository.post(
      `${resource}/property/${companyId}`,
      payload,
      config
    );
  },

  updateProperty(user, spotPropertyId, payload) {
    var config = { headers: getAuthHeader(user) };

    return Repository.put(
      `${resource}/property/${spotPropertyId}`,
      payload,
      config
    );
  },

  deleteProperty(user, spotPropertyId) {
    var config = { headers: getAuthHeader(user) };

    return Repository.delete(`${resource}/property/${spotPropertyId}`, config);
  },

  getEvents(user, siloId, count, page = 0) {
    var config = { headers: getAuthHeader(user) };

    return Repository.get(
      `${resource}/events/${siloId}/${count}/${page}`,
      config
    );
  },

  createEvent(user, siloId, body) {
    var config = { headers: getAuthHeader(user) };

    return Repository.post(`${resource}/events/${siloId}`, body, config);
  },

  createMeta(user, siloId, payload) {
    var config = { headers: getAuthHeader(user) };

    return Repository.post(`${resource}/meta/${siloId}`, payload, config);
  },

  removeMeta(user, siloMetaId) {
    var config = { headers: getAuthHeader(user) };

    return Repository.delete(`${resource}/meta/${siloMetaId}`, config);
  },

  addSiloByImei(user, companyId, imei) {
    var config = { headers: getAuthHeader(user) };

    return Repository.post(
      `${resource}/supplier/${companyId}/${imei}`,
      null,
      config
    );
  },

  removeInvitedSilo(user, companyId, siloId) {
    var config = { headers: getAuthHeader(user) };

    return Repository.delete(
      `${resource}/supplier/${companyId}/${siloId}`,
      config
    );
  },

  modifySupplierSilo(user, siloId, payload) {
    var config = { headers: getAuthHeader(user) };

    return Repository.post(
      `${resource}/supplier/modify/${siloId}`,
      payload,
      config
    );
  },
};
