<template>
  <ul>
    <li class="error-list-item" :key="val + i" v-for="(val, i) in errors">
      {{ val }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      default: () => [],
    },

    isError: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    messages() {
      if (this.errors == null) return [];

      return this.errors;
    },
  },
};
</script>

<style lang="scss" scoped>
.error-list-item {
  color: #ff8a80;
}
</style>