import { RepositoryFactory } from "../../api/RepositoryFactory";

const SiloDataRepo = RepositoryFactory.get("siloData");

export const siloData = {
  namespaced: true,
  state: {},

  actions: {
    async getSiloData({ rootState }, { siloId, from, to }) {
      if (!rootState.users.status.loggedIn) return;

      var user = rootState.users.currentUser;
      return await SiloDataRepo.getSiloData(user, siloId, from, to)
        .then((d) => {
          return d.data;
        })
        .catch((d) => {
          console.error(d);
        });
    },

    async getSiloDataPrediction({ rootState }, { siloId, from }) {
      if (!rootState.users.status.loggedIn) return;

      return await SiloDataRepo.getSiloDataPrediction(siloId, from)
        .then((d) => {
          return d.data;
        })
        .catch((d) => {
          console.error(d);
        });
      },
  },

  mutations: {},
};
