import Vue from "vue";
import Vuex from "vuex";
import { accessTokens } from "./modules/accessToken";
import { alert } from "./modules/alert";
import { companies } from "./modules/company";
import { configuration } from "./modules/configuration";
import { event } from "./modules/event";
import { languages } from "./modules/language";
import { logs } from "./modules/log";
import { mqtt } from "./modules/mqtt";
import { notifications } from "./modules/notification";
import { roles } from "./modules/roles";
import { scripts } from "./modules/scripts";
import { settings } from "./modules/settings";
import { users } from "./modules/user";
import { silos } from "./modules/silos";
import { devices } from "./modules/devices";
import { siloData } from "./modules/siloData";
import { contacts } from "./modules/contacts";
import { alarms } from "./modules/alarms";
import { contents } from "./modules/contents";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    accessTokens,
    alert,
    companies,
    configuration,
    event,
    languages,
    logs,
    mqtt,
    notifications,
    roles,
    scripts,
    settings,
    users,
    silos,
    devices,
    siloData,
    contacts,
    alarms,
    contents,
  },

  plugins: [createPersistedState()],
});
