import store from "../stores/store";

export default {
  HttpStatus: {
    ok: 200,
    created: 201,
    accepted: 202,
    no_content: 204,
    bad_request: 400,
    unauthorized: 401,
    forbidden: 403,
    not_found: 404,
    conflict: 409,
  },

  checkForConflict(response) {
    if (
      response.status === this.HttpStatus.bad_request &&
      response.data.errors != null &&
      response.data.errors.Deveui != null
    )
      return true;
    else return false;
  },
};

export function getAuthHeader(user) {
  return {
    "Access-Control-Allow-Origin": true,
    Authorization: `Bearer ${user.token}`,
    "Content-Type": "application/json",
  };
}

export function getConfig() {
  const user = store.getters["users/user"];

  return { headers: {
    "Access-Control-Allow-Origin": true,
    Authorization: `Bearer ${user.token}`,
    "Content-Type": "application/json",
  }
  };
}
