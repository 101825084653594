<template>
  <v-dialog v-model="dialog" persistent max-width="400px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary lighten-2"
        :icon="icon"
        :text="!icon"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon v-if="icon">{{ iconValue }}</v-icon>
        <span v-else>{{ $t("common.confirm") }}</span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ title }}
        </span>
      </v-card-title>

      <v-card-text>{{ description }}</v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="grey" text @click="dialog = false">{{
          $t("common.cancel")
        }}</v-btn>
        <v-btn color="blue darken-1" text @click="ok">{{
          $t("common.ok")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",

  props: {
    title: {
      type: String,
      default: "",
    },

    description: {
      type: String,
      default: "",
    },

    icon: {
      type: Boolean,
      defualt: false,
    },

    iconValue: {
      type: String,
      default: "mdi-check-circle",
    },
  },

  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    ok() {
      this.$emit("ok");
      this.dialog = false;
    },
  },
};
</script>