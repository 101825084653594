import SiloContentRepository from "../../api/repositories/siloContentRepository";
import i18n from "../../plugins/i18n";

export const contents = {
  namespaced: true,
  state: {
    contents: [],
  },

  actions: {
    async getSiloContents({ commit, rootState }) {
      await SiloContentRepository.getSiloContents(
        rootState.users.currentUser,
        rootState.users.userCompany.companyId
      )
        .then((d) => {
          commit("SET_SILO_CONTENTS", d.data);
        })
        .catch((res) => {
          // eslint-diable-next-line
          console.error(res);
        });
    },

    async createSiloContent({ dispatch, rootState }, payload) {
      await SiloContentRepository.createSiloContent(
        rootState.users.currentUser,
        rootState.users.userCompany.companyId,
        payload
      )
        .then(async () => {
          await dispatch("getSiloContents");
          dispatch("alert/success", i18n.t("common.created"), { root: true });
        })
        .catch((d) => {
          console.error(d);
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });
    },

    async updateSiloContent(
      { dispatch, rootState },
      { siloContentId, payload }
    ) {
      await SiloContentRepository.updateSiloContent(
        rootState.users.currentUser,
        siloContentId,
        payload
      )
        .then(async () => {
          await dispatch("getSiloContents");
          dispatch("alert/success", i18n.t("common.created"), { root: true });
        })
        .catch((d) => {
          console.error(d);
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });
    },

    async deleteSiloContent({ dispatch, rootState }, siloContentId) {
      await SiloContentRepository.deleteSiloContent(
        rootState.users.currentUser,
        siloContentId
      )
        .then(async () => {
          await dispatch("getSiloContents");
          dispatch("alert/success", i18n.t("common.created"), { root: true });
        })
        .catch((d) => {
          console.error(d);
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });
    },
  },

  mutations: {
    SET_SILO_CONTENTS(state, contents) {
      state.contents = contents;
    },
  },

  getters: {
    getSiloContentById: (state) => (id) => {
      return state.contents.find((d) => d.siloContentId == id);
    },
  },
};
