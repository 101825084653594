import SettingRepository from "../../api/repositories/settingRepository";

export const configuration = {
  namespaced: true,
  state: {
    darkmode: false,
    MAFA_API_BASE_URL: null,
    userSettings: {
      theme: "Scientific",
      toolbarTransparent: true,
      stickySideMenu: true,
    },
    globalSettings: {},
    license: {},
    companyFields: [],
    companySettings: {},
  },

  actions: {
    setDarkmode({ commit }, message) {
      if (typeof message == "boolean") {
        commit("SET_DARKMODE", message);
      }
    },

    async getBaseUrl({ commit, rootState }) {
      if (
        rootState.configuration.MAFA_API_BASE_URL == undefined ||
        rootState.configuration.MAFA_API_BASE_URL == null
      ) {
        try {
          const envConfig = await fetch("/environmentConfig.json");
          var envJson = await envConfig.json();
          commit("SET_BASE_URL", envJson.MAFA_API_BASE_URL);
        } catch (exception) {
          commit("SET_BASE_URL", process.env.VUE_APP_MAFA_API_BASE_URL);
        }
      }
    },

    async getUserSettings({ commit, rootState, dispatch }) {
      var user = rootState.users.currentUser;
      if (user === undefined || user === null) return;
      await SettingRepository.get(user).then((data) =>
        commit("SET_USER_SETTINGS", data.data)
      );

      await dispatch("users/getUserPermissionsByCurrentUser", null, {
        root: true,
      });
    },

    async setUserSettings({ dispatch, rootState }, payload) {
      var user = rootState.users.currentUser;
      await SettingRepository.set(user, payload).then(() =>
        dispatch("getUserSettings")
      );
    },

    async getGlobalSettings({ commit, rootState }) {
      var user = rootState.users.currentUser;
      if (user === undefined || user === null) return;

      await SettingRepository.getGlobal(user).then((data) => {
        commit("SET_GLOBAL_SETTINGS", data.data);
      });
    },

    async setGlobalSettings({ dispatch, rootState }, payload) {
      var user = rootState.users.currentUser;
      await SettingRepository.setGlobal(user, payload).then(() =>
        dispatch("getGlobalSettings")
      );
    },

    async setCompanySettings({ dispatch, rootState }, payload) {
      var user = rootState.users.currentUser;
      var company = rootState.users.userCompany.companyId;

      await SettingRepository.setCompany(user, company, payload).then(() =>
        dispatch("getCompanySettings")
      );
    },

    async getCompanySettings({ dispatch, commit, rootState }) {
      var user = rootState.users.currentUser;
      var company = rootState.users.userCompany.companyId;

      // Make sure to reset the fields and settings before
      commit("SET_COMPANY_FIELDS", []);
      commit("SET_COMPANY", {});
      await SettingRepository.getCompany(user, company).then((data) => {
        commit("SET_COMPANY", data.data);
      });

      // Get the fields
      await dispatch("getCompanyFields");
    },

    async getCompanyFields({ commit, rootState }) {
      var user = rootState.users.currentUser;

      await SettingRepository.getCompanyFields(user).then((data) => {
        commit("SET_COMPANY_FIELDS", data.data);
      });
    },

    setCompanySettingLocal({ commit }, { key, value }) {
      commit("SET_COMPANY_SETTING", { key, value });
    },
  },

  mutations: {
    SET_DARKMODE(state, message) {
      state.darkmode = message;
    },
    SET_BASE_URL(state, url) {
      state.MAFA_API_BASE_URL = url;
    },

    SET_USER_SETTINGS(state, payload) {
      for (var i in Object.keys(payload)) {
        let key = Object.keys(payload)[i];
        if (payload[key] == "true" || payload[key] == "false")
          payload[key] = payload[key] == "true" ? true : false;
      }

      state.userSettings = payload;
    },

    SET_LICENSE(state, payload) {
      state.license = payload;
    },

    SET_GLOBAL_SETTINGS(state, payload) {
      state.globalSettings = payload;
    },

    SET_COMPANY(state, payload) {
      state.companySettings = payload;
    },

    SET_COMPANY_FIELDS(state, payload) {
      state.companyFields = payload;
    },

    SET_COMPANY_SETTING(state, keyValue) {
      state.companySettings[keyValue.key] = keyValue.value;
    },
  },
};
