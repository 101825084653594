import UsersRepository from "./repositories/usersRepository";
import CompanyRepository from "./repositories/companyRepository";
import ScriptRepository from "./repositories/scriptRepository";
import LogRepository from "./repositories/logRepository";
import NotificationRepository from "./repositories/notificationRepository";
import LicenseRepository from "./repositories/licenseRepository";
import IntegrationsRepository from "./repositories/integrationsRepository";
import RoleRepository from "./repositories/roleRepository";
import SiloRepository from "./repositories/siloRepository";
import SiloDataRepository from "./repositories/siloDataRepository";
import DeviceRepository from "./repositories/deviceRepository";

const repositories = {
  users: UsersRepository,
  companies: CompanyRepository,
  script: ScriptRepository,
  log: LogRepository,
  notification: NotificationRepository,
  license: LicenseRepository,
  integrations: IntegrationsRepository,
  roles: RoleRepository,
  silos: SiloRepository,
  devices: DeviceRepository,
  siloData: SiloDataRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
