import AlarmRepository from "../../api/repositories/alarmRepository";
import i18n from "../../plugins/i18n";

export const alarms = {
  namespaced: true,
  state: {
    alarms: [],
    alarmActives: [],
  },

  actions: {
    async getAlarms({ commit, rootState }) {
      await AlarmRepository.getAlarms(
        rootState.users.currentUser,
        rootState.users.userCompany.companyId
      )
        .then((d) => {
          commit("SET_ALARMS", d.data);
        })
        .catch((res) => {
          // eslint-diable-next-line
          console.error(res);
        });
    },

    async getAlarmActives({ rootState, commit }) {
      return await AlarmRepository.getAlarmActives(
        rootState.users.currentUser,
        rootState.users.userCompany.companyId
      )
        .then((d) => commit("GET_ACTIVES", d.data))
        .catch((d) => console.error(d));
    },

    async getAlarmActivesBySilo({ rootState, commit }, siloId) {
      return await AlarmRepository.getAlarmActivesBySilo(
        rootState.users.currentUser,
        siloId
      )
        .then((d) => commit("GET_ACTIVES", d.data))
        .catch((d) => console.error(d));
    },

    async getHistories({ rootState }) {
      return await AlarmRepository.getHistories(
        rootState.users.currentUser,
        rootState.users.userCompany.companyId
      )
        .then((d) => d.data)
        .catch((res) => {
          console.error(res);
        });
    },

    async getHistoriesBySilo({ rootState }, siloId) {
      return await AlarmRepository.getHistoriesBySilo(
        rootState.users.currentUser,
        siloId
      )
        .then((d) => d.data)
        .catch((d) => console.error(d));
    },

    async getAlarmsBySilo({ rootState, commit }, siloId) {
      if (siloId == null) {
        commit("SET_ALARMS", []);
        return;
      }

      await AlarmRepository.getAlarmsBySilo(rootState.users.currentUser, siloId)
        .then((d) => {
          commit("SET_ALARMS", d.data);
        })
        .catch((d) => console.error(d));
    },

    async createAlarm({ dispatch, rootState }, payload) {
      let success = false;
      await AlarmRepository.create(
        rootState.users.currentUser,
        rootState.users.userCompany.companyId,
        payload
      )
        .then(async () => {
          await dispatch("getAlarms");
          await dispatch("alert/success", i18n.t("common.created"), {
            root: true,
          });
          success = true;
        })
        .catch(async (res) => {
          console.error(res);
          await dispatch("alert/error", i18n.t("common.error"), { root: true });
          success = false;
        });

      return success;
    },

    async updateAlarm({ dispatch, rootState }, { alarmId, payload }) {
      let success = false;
      await AlarmRepository.update(
        rootState.users.currentUser,
        alarmId,
        payload
      )
        .then(async () => {
          await dispatch("getAlarms");
          await dispatch("alert/success", i18n.t("common.updated"), {
            root: true,
          });
          success = true;
        })
        .catch(async (res) => {
          console.error(res);
          await dispatch("alert/error", i18n.t("common.error"), { root: true });
          success = false;
        });

      return success;
    },

    async deleteAlarm({ dispatch, rootState }, alarmId) {
      let success = false;
      await AlarmRepository.delete(rootState.users.currentUser, alarmId)
        .then(async () => {
          await dispatch("getAlarms");
          await dispatch("alert/success", i18n.t("common.deleted"), {
            root: true,
          });
          success = true;
        })
        .catch(async (res) => {
          console.error(res);
          await dispatch("alert/error", i18n.t("common.error"), { root: true });
          success = false;
        });

      return success;
    },

    async acknowledgeAlarm({ dispatch, rootState }, { alarmId, siloId }) {
      let success = false;
      await AlarmRepository.acknowledge(
        rootState.users.currentUser,
        alarmId,
        siloId
      )
        .then(async () => {
          await dispatch("getAlarms");
          await dispatch("alert/success", i18n.t("common.ok"), { root: true });
          success = true;
        })
        .catch(async (res) => {
          console.error(res);
          await dispatch("alert/error", i18n.t("common.error"), { root: true });
          success = false;
        });

      return success;
    },
  },

  mutations: {
    SET_ALARMS(state, alarms) {
      state.alarms = alarms;
    },

    GET_ACTIVES(state, actives) {
      state.alarmActives = actives;
    },
  },

  getters: {
    getAlarmById: (state) => (id) => {
      return state.alarms.find((d) => d.alarmId == id);
    },

    getActiveAlarmBySilo: (state) => (id) => {
      return state.alarmActives.filter((d) => d.siloId == id);
    },

    getActiveSilos: (state) => {
      // (v, i, a) => a.indexOf(v) === i is to get unique values
      return state.alarmActives
        .map((d) => d.siloId)
        .filter((v, i, a) => a.indexOf(v) === i);
    },

    hasActiveAlarm: (state, getters, rootState) => (id) => {
      var silo = rootState.silos.silos.find((d) => d.siloId == id);
      if (silo && silo.haveActiveAlarm) {
        return true;
      }

      return state.alarmActives.findIndex((e) => e.siloId == id) != -1;
    },
  },
};
