import axios from "axios";
import store from "../stores/store";

let axiosConnection = axios.create();

axiosConnection.interceptors.request.use((config) => {
  config.baseURL = `${store.state.configuration.MAFA_API_BASE_URL}/api`;
  return config;
});

export default axiosConnection;
