import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const contactResource = "/contact";

export default {
  getContacts(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${contactResource}/${companyId}`, config);
  },
};
