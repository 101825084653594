<template>
  <v-container class="searchbox">
    <v-text-field
      prepend-inner-icon="mdi-magnify"
      label="Search"
      style="max-width: 400px; justify-self: center; align-self: center"
      v-model="searchTerm"
      @change="search"
      @keydown="search"
      counter
      hide-details
      autocomplete="off"
      @focus="showResults = true"
      @blur="blur"
    ></v-text-field>

    <v-card class="item-box" v-if="showResults == true">
      <div v-if="Object.keys(items).length <= 0 && !loading">
        <h4 style="padding: 1rem">{{ $t("common.nothingToSee") }}</h4>
      </div>
      <v-progress-linear
        v-if="loading"
        style="wdith: 100%"
        :height="5"
        color="primary"
        indeterminate
      ></v-progress-linear>

      <div
        class="item-category"
        :style="`background: ${typeColor(key)}40`"
        v-for="(value, key) in items"
        :key="key"
      >
        <h3>{{ $t(`common.searchType.${key}`) }}</h3>
        <v-divider :color="typeColor(key)"></v-divider>
        <div class="item" v-for="(item, i) in value" :key="i">
          <h5
            :href="getRoute(item)"
            @mousedown="(e) => navigate(e, item)"
            class="text--darken"
          >
            {{ item.title }}
          </h5>
          <p>{{ item.description }}</p>
        </div>
        <v-divider :color="typeColor(key)"></v-divider>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
  HubConnectionState,
} from "@microsoft/signalr";
import { mapState, mapActions } from "vuex";

export default {
  name: "Search",

  data() {
    return {
      hub: null,
      searchTerm: "",
      debounceTimer: null,
      showResults: false,
      items: [],
      loading: false,
    };
  },

  methods: {
    ...mapActions("companies", ["getById"]),
    ...mapActions("users", ["setUserCompany"]),

    newResult(values) {
      this.items = values;
    },

    search() {
      if (this.hub.connectionState != HubConnectionState.Connected) {
        clearTimeout(this.debounceTimer);
        return;
      }

      if (this.searchTerm == "" || this.searchTerm == null) {
        this.items = [];
        clearTimeout(this.debounceTimer);
        return;
      }

      if (this.debounceTimer != null) clearTimeout(this.debounceTimer);

      this.loading = true;
      this.showResults = true;

      this.debounceTimer = setTimeout(() => {
        if (this.searchTerm.length > 1)
          this.hub.invoke("Search", this.searchTerm);

        this.loading = false;
        clearTimeout(this.debounceTimer);
      }, 500);
    },

    blur() {
      this.showResults = false;
      this.loading = false;
      clearTimeout(this.debounceTimer);
    },

    getRoute(item) {
      switch (item.type) {
        case 0:
          return `/alarm/${item.identifier}`;
        case 1:
          return `/application/${item.identifier}`;
        case 2:
          return `/dashboard/${item.identifier}`;
        case 3:
          return `/gateways`;
        case 4:
          return `/script/${item.identifier}`;
        case 5:
          return `/tagdetails/${item.identifier}`;
      }

      return `/`;
    },

    async navigate(e, item) {
      e.preventDefault();

      if (item.companyId !== this.userCompany.companyId) {
        await this.getById({ companyId: item.companyId });
        this.setUserCompany({ company: this.company });
      }

      this.searchTerm = "";
      this.showResults = false;
      clearTimeout(this.debounceTimer);
      let route = this.getRoute(item);
      let duplicateNav = this.checkDuplicateNavigation(item.type);
      this.$router.push(route);
      if (duplicateNav) this.$router.go();
    },

    checkDuplicateNavigation(type) {
      let t = 0;
      switch (type) {
        case 0:
          t = `alarm`;
          break;
        case 1:
          t = `application`;
          break;
        case 2:
          t = `dashboard`;
          break;
        case 3:
          t = `gateways`;
          break;
        case 4:
          t = `script`;
          break;
        case 5:
          t = `tagdetails`;
          break;
      }

      return this.$route.path.includes(t);
    },

    async connect() {
      var url = this.MAFA_API_BASE_URL;
      url = url + "/search-hub";

      this.hub = new HubConnectionBuilder()
        .withUrl(url, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: () => this.currentUser.token,
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();

      this.hub.on("BroadcastSearch", this.newResult);
      await this.hub.start();
    },

    typeColor(type) {
      switch (type) {
        case "TAG":
          return "#3D56B2";
        case "ALARM":
          return "#911F27";
        case "APPLICATION":
          return "#57CC99";
        case "DASHBOARD":
          return "#FFC074";
        case "GATEWAY":
          return "#00A19D";
        case "SCRIPT":
          return "#FFEF78";
      }

      return "";
    },
  },

  computed: {
    ...mapState("configuration", ["MAFA_API_BASE_URL"]),
    ...mapState("users", ["currentUser", "userCompany"]),
    ...mapState("companies", ["company"]),
  },

  async created() {
    await this.connect();
  },

  unmounted() {
    if (this.debounceTimer != null) clearTimeout(this.debounceTimer);

    this.hub.off("BroadcastSearch", this.newResult);
    this.hub.stop();
  },
};
</script>

<style lang="scss" scoped>
.searchbox {
  max-width: 400px;
}

.item-box {
  position: absolute;
  max-width: 100%;
  width: 400px;
  border-radius: 10px;
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
  .item-category {
    padding: 1rem;
    .item {
      padding: 0.5rem;
      h5 {
        margin-bottom: 0px;
        font-weight: bolder;
        font-size: 1rem;
        text-decoration: none;
        cursor: pointer;
      }

      p {
        font-size: 0.7rem;
        margin: 0;
      }
    }
  }
}

@media (max-width: 480px) {
  .item-box {
    width: 100%;
    left: 0;
  }
}
</style>