import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/alarm";

export default {
  getAlarms(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${companyId}`, config);
  },

  getActiveHistory(user, companyId) {
    var config = { headers: getAuthHeader(user) };

    return Repository.get(`${resource}/active/history/${companyId}`, config);
  },

  getAlarmActives(user, companyId) {
    var config = { headers: getAuthHeader(user) };

    return Repository.get(`${resource}/active/${companyId}`, config);
  },

  getAlarmActivesBySilo(user, siloId) {
    var config = { headers: getAuthHeader(user) };

    return Repository.get(`${resource}/active/silo/${siloId}`, config);
  },

  getHistoriesBySilo(user, siloId) {
    var config = { headers: getAuthHeader(user) };

    return Repository.get(`${resource}/silo/history/${siloId}`, config);
  },

  getAlarmsBySilo(user, siloId) {
    var config = { headers: getAuthHeader(user) };

    return Repository.get(`${resource}/silo/${siloId}`, config);
  },

  create(user, companyId, payload) {
    var config = { headers: getAuthHeader(user) };

    return Repository.post(`${resource}/${companyId}`, payload, config);
  },

  update(user, alarmId, payload) {
    var config = { headers: getAuthHeader(user) };

    return Repository.put(`${resource}/${alarmId}`, payload, config);
  },

  delete(user, alarmId) {
    var config = { headers: getAuthHeader(user) };

    return Repository.delete(`${resource}/${alarmId}`, config);
  },

  acknowledge(user, alarmId, siloId) {
    var config = { headers: getAuthHeader(user) };

    return Repository.post(
      `${resource}/acknowledge/${alarmId}/${siloId}`,
      null,
      config
    );
  },

  getSilosInAlarmActives(user, companyId) {
    var config = { headers: getAuthHeader(user) };

    return Repository.get(`${resource}/silos/${companyId}`, config);
  },
};
