import { RepositoryFactory } from "../../api/RepositoryFactory";
import i18n from "../../plugins/i18n";

const SiloRepo = RepositoryFactory.get("silos");

export const silos = {
  namespaced: true,
  state: {
    silos: [],
    properties: [],
  },

  actions: {
    async getSilos({ commit, rootState }) {
      if (!rootState.users.status.loggedIn) return;

      var user = rootState.users.currentUser;
      var companyId = rootState.users.userCompany.companyId;
      await SiloRepo.getSilos(user, companyId)
        .then((silos) => {
          commit("GET_SILOS", silos.data);
        })
        .catch((res) => {
          // eslint-disable-next-line
          console.error(res);
        });
    },

    async getSiloProperties({ commit, rootState }) {
      if (!rootState.users.status.loggedIn) return;

      var user = rootState.users.currentUser;
      var companyId = rootState.users.userCompany.companyId;
      await SiloRepo.getSiloProperties(user, companyId)
        .then((properties) => {
          commit("GET_PROPERTIES", properties.data);
        })
        .catch((res) => {
          // eslint-disable-next-line
          console.error(res);
        });
    },

    async createProperty({ dispatch, rootState }, payload) {
      if (!rootState.users.status.loggedIn) return;

      let success = false;
      var user = rootState.users.currentUser;
      var companyId = rootState.users.userCompany.companyId;
      await SiloRepo.createProperty(user, companyId, payload)
        .then(async () => {
          await dispatch("getSiloProperties");
          dispatch("alert/success", i18n.t("common.created"), { root: true });
          success = true;
        })
        .catch((res) => {
          // eslint-disable-next-line
          console.error(res);
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });

      return success;
    },

    async updateProperty({ dispatch, rootState }, { siloPropertyId, payload }) {
      if (!rootState.users.status.loggedIn) return;

      let success = false;
      var user = rootState.users.currentUser;
      await SiloRepo.updateProperty(user, siloPropertyId, payload)
        .then(async () => {
          await dispatch("getSiloProperties");
          dispatch("alert/success", i18n.t("common.updated"), { root: true });
          success = true;
        })
        .catch((res) => {
          // eslint-disable-next-line
          console.error(res);
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });

      return success;
    },

    async deleteProperty({ dispatch, rootState }, siloPropertyId) {
      if (!rootState.users.status.loggedIn) return;

      let success = false;
      await SiloRepo.deleteProperty(rootState.users.currentUser, siloPropertyId)
        .then(async () => {
          await dispatch("getSiloProperties");
          dispatch("alert/success", i18n.t("common.deleted"), { root: true });
          success = true;
        })
        .catch((res) => {
          console.error(res);
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });

      return success;
    },

    async createSilo({ dispatch, rootState }, payload) {
      if (!rootState.users.status.loggedIn) return;

      let success = false;
      var user = rootState.users.currentUser;
      var companyId = rootState.users.userCompany.companyId;
      await SiloRepo.createSilo(user, companyId, payload)
        .then(async () => {
          await dispatch("getSilos");
          dispatch("alert/success", i18n.t("common.created"), { root: true });

          success = true;
        })
        .catch((res) => {
          // eslint-disable-next-line
          console.error(res);
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });

      return success;
    },

    async updateSilo({ dispatch, rootState }, { siloId, payload }) {
      if (!rootState.users.status.loggedIn) return;

      let success = false;
      var user = rootState.users.currentUser;
      await SiloRepo.updateSilo(user, siloId, payload)
        .then(async () => {
          await dispatch("getSilos");
          dispatch("alert/success", i18n.t("common.updated"), { root: true });

          success = true;
        })
        .catch((res) => {
          // eslint-disable-next-line
          console.error(res);
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });

      return success;
    },

    async deleteSilo({ dispatch, rootState }, siloId) {
      if (!rootState.users.status.loggedIn) return;

      let success = false;
      await SiloRepo.deleteSilo(rootState.users.currentUser, siloId)
        .then(async () => {
          await dispatch("getSilos");
          dispatch("alert/success", i18n.t("common.deleted"), { root: true });
          success = true;
        })
        .catch((res) => {
          console.error(res);
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });

      return success;
    },

    async createMeta({ dispatch, rootState }, { siloId, payload }) {
      if (!rootState.users.status.loggedIn) return;

      var user = rootState.users.currentUser;
      await SiloRepo.createMeta(user, siloId, payload)
        .then(async () => {
          await dispatch("getSilos");
          dispatch("alert/success", i18n.t("common.created"), { root: true });
        })
        .catch((d) => {
          console.error(d);
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });
    },

    async removeMeta({ dispatch, rootState }, siloMetaId) {
      if (!rootState.users.status.loggedIn) return;

      var user = rootState.users.currentUser;
      await SiloRepo.removeMeta(user, siloMetaId)
        .then(async () => {
          await dispatch("getSilos");
          dispatch("alert/success", i18n.t("common.created"), { root: true });
        })
        .catch((d) => {
          console.error(d);
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });
    },

    async getEvents({ rootState }, { siloId, count, page = 0 }) {
      if (!rootState.users.status.loggedIn) return;

      var user = rootState.users.currentUser;
      return await SiloRepo.getEvents(user, siloId, count, page)
        .then((d) => d.data)
        .catch((d) => {
          console.error(d);
        });
    },

    async createEvent({ rootState, dispatch }, { siloId, payload }) {
      if (!rootState.users.status.loggedIn) return;

      var user = rootState.users.currentUser;
      return await SiloRepo.createEvent(user, siloId, payload)
        .then(() => {
          dispatch("alert/success", i18n.t("common.created"), { root: true });
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });
    },

    async addSiloByImei({ rootState, dispatch }, imei) {
      let result = false;
      await SiloRepo.addSiloByImei(
        rootState.users.currentUser,
        rootState.users.userCompany.companyId,
        imei
      )
        .then(async () => {
          dispatch("alert/success", i18n.t("common.created"), { root: true });
          await dispatch("getSilos");
          result = true;
        })
        .catch((d) => {
          console.error(d);
          result = false;
        });

      return result;
    },

    async removeInvitedSilo({ rootState, dispatch }, siloId) {
      let result = false;
      await SiloRepo.removeInvitedSilo(
        rootState.users.currentUser,
        rootState.users.userCompany.companyId,
        siloId
      )
        .then(async () => {
          dispatch("alert/success", i18n.t("common.created"), { root: true });
          await dispatch("getSilos");
          result = true;
        })
        .catch((d) => {
          console.error(d);
          result = false;
        });

      return result;
    },

    async modifySupplierSilo({ rootState, dispatch }, { siloId, payload }) {
      let result = false;
      await SiloRepo.modifySupplierSilo(
        rootState.users.currentUser,
        siloId,
        payload
      )
        .then(async () => {
          dispatch("alert/success", i18n.t("common.created"), { root: true });
          await dispatch("getSilos");
          result = true;
        })
        .catch((d) => {
          console.error(d);
          result = false;
        });

      return result;
    },
  },

  getters: {
    getSiloById: (state) => (id) => {
      return state.silos.find((d) => d.siloId == id);
    },

    getPropertyById: (state) => (id) => {
      return state.properties.find((d) => d.siloPropertyId == id);
    },
  },

  mutations: {
    GET_SILOS(state, silos) {
      state.silos = silos;
    },

    GET_PROPERTIES(state, properties) {
      state.properties = properties;
    },
  },
};
